<template>
  <section class="news">
    <v-img
      v-if="info.banners[0]"
      :src="info.banners[0].img"
      class="tnf-top-banner"
    >
    </v-img>
    <v-container grid-list-md text-center id="tnf-news-top">
      <v-layout wrap mb-12>
        <v-flex xs12 sm12 md4 lg4 xl4 :key="n" v-for="(item, n) in news.data">
          <v-layout wrap ma-md-4 ma-lg-4 ma-xl-4>
            <v-flex xs12 sm12 md12 lg12 xl12 pa-6  class="text-left tnf-news-card" >
              <h5 class="tnf-news-date">{{item.create_time.substring(0, 10)}}</h5>
              <h3 class="tnf-news-title">{{item.title}}</h3>
              <section class="tnf-news-profile">{{item.profile}}</section>
              <div class="text-left"><v-btn outlined color="#0C65A5" width="120" :to="'/new/' + item.id">详情</v-btn></div>
            </v-flex>
          </v-layout>
        </v-flex>
      </v-layout>
      <template>
        <div class="text-center">
          <v-pagination
            v-model="page"
            :length="totalPage"
            circle
          ></v-pagination>
        </div>
      </template>
    </v-container>
  </section>
</template>
<script>
export default {
  data: () => ({
    newsType: 2,
    pageId: 4,
    news: [],
    totalPage: 1,
    page: null
  }),
  created () {
    this.page = 1
  },
  watch: {
    '$store.getters.companyInfo': {
      handler: function (val, oldVal) {
        this.info.banners = val.banner[this.pageId]
      },
      deep: true
    },
    params: {
      handler: function (val, oldVal) {
        let type = val.newsType
        let page = val.page
        document.querySelector('#tnf-news-top').scrollIntoView(true)
        this.getNews(type, page)
      },
      deep: true
    }
  },
  computed: {
    info () {
      let banners = {}
      banners[this.pageId] = []
      if (this.$store.getters.companyInfo.banner) {
        banners = this.$store.getters.companyInfo.banner[this.pageId]
      }
      return {
        banners: banners
      }
    },
    params () {
      const { newsType, page } = this
      return {
        newsType,
        page
      }
    }
  },
  methods: {
    getNews (type = 2, page = 1) {
      this.totalPage = 1
      this.https.get('news', { type: type, page: page }).then(response => {
        if (response.code === 0) {
          this.news = response.data
          this.totalPage = this.news.last_page
          this.page = page
        }
      })
    }
  }
}
</script>
<style lang="css">
  @import '../css/news.css';
</style>
